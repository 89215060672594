import React, { Component } from "react";
import WelcomePage from '../../components/Welcome';
import "./styles.css";

export default class Welcome extends Component {
    render() {
        return  (
            <div className="Main-app">
                <WelcomePage />
            </div>
        )
    }
}