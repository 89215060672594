import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import "./styles.css";

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

const quote = getRndInteger(1,6);

class Main extends Component {
    
  render() {
      // console.log('WELCOME PROPS: ', this.props.state);
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className={"loginScreen" + quote} disableGutters="true">
            <Container maxWidth="false" disableGutters="true" className="welcomeDialog fadeDialog" >
                {
                  this.props.path === '/fr' ?
                    <Typography variant="h5" className="welcomeText fadeDown">
                      Bienvenue,
                    </Typography>
                  : this.props.path === '/it' ?
                    <Typography variant="h5" className="welcomeText fadeDown">
                      Benvenuta,
                    </Typography>
                  : 
                    <Typography variant="h5" className="welcomeText fadeDown">
                      Willkommen,
                    </Typography>
                }
                <Typography variant="h5" className="welcomeName fadeDown">
                    {this.props.state.Firstname} {this.props.state.Name}
                </Typography>
            </Container>
            {
              window.location.pathname.includes('/fr') ?
                <Container className="welcomeQuote fadeUp">
                  <Typography variant="body2">
                    Le saviez-vous:
                  </Typography>
                  {
                    quote === 1 ?
                      <Typography variant="h6">
                        En moyenne, plus de 150 trains circulent chaque jour sur chaque tronçon ferroviaire suisse. 
                      </Typography>
                    : quote === 2 ?
                      <Typography variant="h6">
                        Le tunnel de base du Gothard (57 km) est le plus long tunnel ferroviaire du monde.
                      </Typography>
                    : quote === 3 ?
                      <Typography variant="h6">
                        La Suisse compte au total environ 25 000 arrêts de transports publics.
                      </Typography>
                    : quote === 4 ?
                      <Typography variant="h6">
                        Le réseau de bus de la Suisse a une longueur totale de plus de 20 000 kilomètres.
                      </Typography>
                    : quote === 5 ?
                      <Typography variant="h6">
                        L'abonnement général a été introduit en 1898 déjà.
                      </Typography>
                    :
                      <Typography variant="h6">
                        Le Uri, construit en 1901, est le plus ancien bateau à vapeur à roues à aube encore en activité en Suisse.
                      </Typography>
                  }
                </Container>
              : window.location.pathname.includes('/it') ?
                <Container className="welcomeQuote fadeUp">
                  <Typography variant="body2">
                    Lo sapevate:
                  </Typography>
                  {
                    quote === 1 ?
                      <Typography variant="h6">
                        In Svizzera circolano su ogni tratta in media oltre 150 treni al giorno.
                      </Typography>
                    : quote === 2 ?
                      <Typography variant="h6">
                        La galleria di base del San Gottardo (57 km) è la galleria ferroviaria più lunga al mondo.
                      </Typography>
                    : quote === 3 ?
                      <Typography variant="h6">
                        In Svizzera ci sono complessivamente circa 25 000 fermate dei trasporti pubblici.
                      </Typography>
                    : quote === 4 ?
                      <Typography variant="h6">
                        La rete di autobus in Svizzera ha una lunghezza di oltre 20,000 chilometri.
                      </Typography>
                    : quote === 5 ?
                      <Typography variant="h6">
                        L'abbonamento generale è stato introdotto già nel 1898.
                      </Typography>
                    :
                      <Typography variant="h6">
                        L'Uri (anno di costruzione 1901) è il più vecchio piroscafo a ruote in funzione della Svizzera.
                      </Typography>
                  }
                </Container>
              :
                <Container className="welcomeQuote fadeUp">
                  <Typography variant="body2">
                    Wussten Sie schon:
                  </Typography>
                  {
                    quote === 1 ?
                      <Typography variant="h6">
                        In der Schweiz verkehren auf jeder Strecke durchschnittlich über 150 Züge pro Tag.
                      </Typography>
                    : quote === 2 ?
                      <Typography variant="h6">
                        Der Gotthard-Basistunnel (57 km) ist der längste Eisenbahntunnel der Welt.
                      </Typography>
                    : quote === 3 ?
                      <Typography variant="h6">
                        In der Schweiz gibt es insgesamt rund 25’000 Haltestellen des öffentlichen Verkehrs.
                      </Typography>
                    : quote === 4 ?
                      <Typography variant="h6">
                        Das Busnetz in der Schweiz hat eine Länge von mehr als 20'000 Kilometer.
                      </Typography>
                    : quote === 5 ?
                      <Typography variant="h6">
                        Das Generalabonnement wurde bereits 1898 eingeführt.
                      </Typography>
                    :
                      <Typography variant="h6">
                        Die Uri (Baujahr 1901) ist der älteste aktive Schaufelraddampfer der Schweiz.
                      </Typography>
                  }
                </Container>
            }
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);