import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import AppBar from '../../Shared/AppBar';
import axios from 'axios';
import querystring from 'querystring';
import ThankYou from '../../assets/icons/thankyou.png';
import './styles.css';

class Main extends Component {

  constructor(props) {
    super(props);
    window.location.pathname.includes('/fr') ?
    this.state = {
      page: 'Merci',
      back: true,
      prev: '',
      dialogError: '',
      dialogSuccess: '',
      successFade: 'out',
      fade: 'out'
    }
    : window.location.pathname.includes('/it') ?
    this.state = {
      page: 'Grazie',
      back: true,
      prev: '',
      dialogError: '',
      dialogSuccess: '',
      successFade: 'out',
      fade: 'out'
    }
    :
    this.state = {
      page: 'Vielen Dank',
      back: true,
      prev: '',
      dialogError: '',
      dialogSuccess: '',
      successFade: 'out',
      fade: 'out'
    }
  }

  backButtonHandler = () => {
    if (this.state.page === 'Vielen Dank') {
      this.props.history.push('/de')
    }
    if (this.state.page === 'Merci') {
      this.props.history.push('/fr')
    }
    if (this.state.page === 'Grazie') {
      this.props.history.push('/it')
    }
  }

  forgotPinHandler = (event) => {
    event.preventDefault();
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://api.ga20.dev.mxm.ch/api/authentication/ResetPinRequest'

    if (!event.target[0].value.includes("@")) {
      this.setState({
        dialogError: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
        fade: 'in'
      })
    } else {
      axios.post(proxyUrl + targetUrl, querystring.stringify({
        email: event.target[0].value,
      }))
  
      .then(response => {
        if (response.status === 200) {
          // console.log('Reset Pin Sent');
          this.setState({
            dialogSuccess: 'Danke. Sie erhalten eine E-Mail mit Ihrem PIN.',
            successFade: 'in',
            fade: 'out'
          })
        }
        // console.log('Login Response', response);
      })
      .catch(error => {
        console.log('There is an error: ', error.message)
        if (error.message === 'Request failed with status code 400') {
          this.setState({
            dialogError: 'Wir konnten kein Konto unter dieser E-Mail-Adresse finden, bitte überprüfen Sie Ihre Eingabe.',
            fade: 'in'
          })
        } else {
          this.setState({
            dialogError: '' + error.message,
            fade: 'in'
          })
        }
      });
    }
  }

  render() {
    // console.log('FORGOT PIN: ', this.state);
    
    return (
      <React.Fragment>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
        <CssBaseline />
        <Container className="finishSection" maxWidth="false">
          <div>
            <div className="checkMark">
              <img src={ThankYou} alt="Vielen Dank!" />
            </div>
            {
              window.location.pathname.includes('/fr') ?
                <p>Merci pour votre participation. Nous vous souhaitons de continuer à avoir du plaisir à utiliser les transports publics.</p>
              : window.location.pathname.includes('/it') ?
                <p>Grazie per la sua partecipazione. Le auguriamo ulteriori soddisfazioni e un buon viaggio con i trasporti pubblici.</p>
              :
                <p>Herzlichen Dank für Ihre Teilnahme. Wir wünschen Ihnen weiterhin viel Freude und gute Fahrt mit dem öffentlichen Verkehr.</p>
            }
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);