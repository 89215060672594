import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "./styles.css";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import axios from "axios";
import querystring from "querystring";

class Main extends Component {
  constructor() {
    super();
    this.state = {
      dialogError: "",
      fade: "out",
      fetchingUser: true,
    };
  }

  componentDidMount() {
    this.languageRouter();
    if (localStorage.AccessToken === undefined) {
      localStorage.clear();
    } else {
      this.props.history.push(window.location.pathname + "/home");
    }

    if (this.props.location.search === "?locked") {
      window.location.pathname === "/fr"
        ? this.setState({
            dialogError:
              "Le journal de voyage a déjà été finalisé, il n'est plus possible de le modifier.",
            fade: "in",
          })
        : window.location.pathname === "/it"
        ? this.setState({
            dialogError:
              "Il giornale di viaggio è già concluso. Non sono più consentite modifiche.",
            fade: "in",
          })
        : this.setState({
            dialogError:
              "Das Reisejournal ist bereits abgeschlossen. Änderungen sind nicht mehr möglich.",
            fade: "in",
          });
    }

    // console.log('HOST: ', window.location.pathname );
    // console.log('PATH: ', window.location.pathname );

    let params = querystring.parse(this.props.location.search);
    // console.log('PARAMS: ', params["?A"]);

    if (params["?A"] === undefined) {
      // console.log('Params NULL');
    } else {
      localStorage.clear();
      var proxyUrl = "https://crocs-ga20.herokuapp.com/",
        targetUrl =
          "https://api.ga20.dev.mxm.ch/api/authentication/UserCredentials?param=";

      var myHeaders = new Headers();
      myHeaders.append("Cookie", "lang=de");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(proxyUrl + targetUrl + params["?A"], requestOptions)
        .then((response) => response.json())
        .then((result) => {
          axios
            .post(
              "https://api.ga20.dev.mxm.ch/token",
              querystring.stringify({
                Pin: result.Pin,
                Lastname: result.Lastname,
                grant_type: "password",
              })
            )

            .then((response) => {
              window.localStorage.setItem(
                "AccessToken",
                "Bearer " + response.data.access_token
                );

                this.userProfileHandler();

              // window.location.pathname.includes('/fr') ?  'Non, la méthodologie de l\'enquête ne le permet malheureusement pas.' : window.location.pathname.includes('/it') ? 'No, per motivi legati al metodo di rilevamento ciò non è possibile.' : 'Nein, das ist leider aus Gründen der Erhebungsmethodik nicht möglich.'
            })
            .catch((error) => {
              console.log("There is an error: ", error.message);
              if (error.message === "Request failed with status code 400") {
                this.setState({
                  dialogError:
                    "Diese Angaben sind ungültig, bitte überprüfen Sie PIN und/oder Nachname.",
                  fade: "in",
                });
              } else {
                this.setState({
                  dialogError: "" + error.message,
                  fade: "in",
                });
              }
            });
        })
        .catch((error) => console.log("error", error));
    }

    if (params.Lastname === undefined) {
      console.log("Params NULL");
    } else {
      // console.log('Params NOT NULL');
      // console.log(params.Pin);
      // console.log(params.Lastname);

      axios
        .post(
          "https://api.ga20.dev.mxm.ch/token",
          querystring.stringify({
            Pin: params.Pin,
            Lastname: params.Lastname,
            grant_type: "password",
          })
        )

        .then((response) => {
          window.localStorage.setItem(
            "AccessToken",
            "Bearer " + response.data.access_token
          );
          this.props.history.push(window.location.pathname + "/welcome");
        })
        .catch((error) => {
          console.log("There is an error: ", error.message);
          if (error.message === "Request failed with status code 400") {
            this.setState({
              dialogError:
                "Diese Angaben sind ungültig, bitte überprüfen Sie PIN und/oder Nachname.",
              fade: "in",
            });
          } else {
            this.setState({
              dialogError: "" + error.message,
              fade: "in",
            });
          }
        });
    }
    console.log("STATE ON LOGIN: ", this.state);
  }
  userProfileHandler = () => {
    console.log("In the check user!");
    var proxyUrl = "https://crocs-ga20.herokuapp.com/",
      targetUrl = "https://api.ga20.dev.mxm.ch/api/authentication/userInfo";

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            userInfo: result,
            fetchingUser: false,
          },
          () => {
            console.log("USER INFO:=======", this.state.userInfo);
            let language;
            language =
              this.state.userInfo.Preload.Language === 1
                ? "de"
                : this.state.userInfo.Preload.Language === 2
                ? "fr"
                : "it";
            let pin = this.state.userInfo.Preload.Pin;
            if (this.state.userInfo.Preload.ProjectCode === "GA21") {
              window.localStorage.clear();
              window.location.replace(
                `https://ga21.allianceswisspass.ch/${language}/?TNC=${pin}`
              );
            } else {
              this.props.history.push(window.location.pathname + "/welcome");
            }
          }
        );
      })
      .catch((error) => console.log("error", error));
  };
  languageRouter = () => {
    if (
      window.location.host === "localhost:3000" &&
      window.location.pathname === "/"
    ) {
      this.props.history.push("/de");
    }
    if (
      window.location.host === "ga20.dev.mxm.ch" &&
      window.location.pathname === "/"
    ) {
      this.props.history.push("/de");
    }
    if (
      window.location.host === "ga.allianceswisspass.ch" &&
      window.location.pathname === "/"
    ) {
      this.props.history.push("/de");
    }
    if (
      window.location.host === "ag.allianceswisspass.ch" &&
      window.location.pathname === "/"
    ) {
      this.props.history.push("/fr");
    }
    if (
      window.location.host === "agquestionario.allianceswisspass.ch" &&
      window.location.pathname === "/"
    ) {
      this.props.history.push("/it");
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        "https://api.ga20.dev.mxm.ch/token",
        querystring.stringify({
          Pin: event.target[0].value,
          Lastname: event.target[1].value,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
        this.userProfileHandler();
      })
      .catch((error) => {
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError:
                  "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError:
                  "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
              })
            : this.setState({
                dialogError:
                  "Diese Angaben sind ungültig. Bitte überprüfen Sie PIN und/oder Name und versuchen Sie es erneut.",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="loginScreen">
          <Card raised="true" className={"errorDialog fade-" + this.state.fade}>
            <CardContent className="errorContent">
              <Typography align="center">{this.state.dialogError}</Typography>
            </CardContent>
          </Card>
                    
                <div className="loginFields">
                    <div>
                    {window.location.pathname === "/fr" ? (
                            <p>Si votre semaine de relevé se situe entre le 05/04/2021 et le 13/06/2021, veuillez cliquer <a href="https://ga21.allianceswisspass.ch/fr">ici</a> pour vous connecter.</p>
                    ) : window.location.pathname === "/it" ? (
                                <p>Se la tua settimana di rilevazione è compresa tra il 05/04/2021 e il 13/06/2021, clicca <a href="https://ga21.allianceswisspass.ch/it">qui</a> per effettuare il login.</p>
                            ) : (
                                    <p>Falls Ihre Erhebungswoche im Zeitraum zwischen dem 05.04.2021 und dem 13.06.2021 liegt, dann klicken Sie zum Login bitte <a href="https://ga21.allianceswisspass.ch/de">hier</a>.</p>
                                )}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
            {window.location.pathname === "/fr" ? (
              <p>Connectez-vous afin de saisir vos voyages.</p>
            ) : window.location.pathname === "/it" ? (
              <p>Acceda ora per registrare i suoi viaggi.</p>
            ) : (
              <p>Bitte melden Sie sich jetzt an, um Ihre Reisen zu erfassen.</p>
            )}
            <form onSubmit={this.handleSubmit}>
              <div className="field">
                {window.location.pathname === "/fr" ? (
                  <TextField
                    fullWidth="true"
                    id="pin"
                    name="pin"
                    label="NIP"
                    variant="filled"
                  />
                ) : window.location.pathname === "/it" ? (
                  <TextField
                    fullWidth="true"
                    id="pin"
                    name="pin"
                    label="PIN"
                    variant="filled"
                  />
                ) : (
                  <TextField
                    fullWidth="true"
                    id="pin"
                    name="pin"
                    label="PIN"
                    variant="filled"
                  />
                )}
              </div>
              <div className="field">
                {window.location.pathname === "/fr" ? (
                  <TextField
                    fullWidth="true"
                    id="nachname"
                    name="nachname"
                    label="Nom"
                    variant="filled"
                  />
                ) : window.location.pathname === "/it" ? (
                  <TextField
                    fullWidth="true"
                    id="nachname"
                    name="nachname"
                    label="Cognome"
                    variant="filled"
                  />
                ) : (
                  <TextField
                    fullWidth="true"
                    id="nachname"
                    name="nachname"
                    label="Name"
                    variant="filled"
                  />
                )}
              </div>
              {window.location.pathname === "/fr" ? (
                <Link className="faqLink loginFielsLink" to="fr/information" target="_blank">
                  FAQ
                </Link>
              ) : window.location.pathname === "/it" ? (
                <Link className="faqLink loginFielsLink" to="it/information" target="_blank">
                  FAQ
                </Link>
              ) : (
                <Link className="faqLink loginFielsLink" to="de/information" target="_blank">
                  FAQ
                </Link>
              )}
              {window.location.pathname === "/fr" ? (
                <Link className="forgotPinLink loginFielsLink" to="fr/forgot/pin">
                  NIP oublié?
                </Link>
              ) : window.location.pathname === "/it" ? (
                <Link className="forgotPinLink loginFielsLink" to="it/forgot/pin">
                  PIN dimenticato?
                </Link>
              ) : (
                <Link className="forgotPinLink loginFielsLink" to="de/forgot/pin">
                  PIN vergessen?
                </Link>
              )}
              <Container className="loginButtonSection">
                {window.location.pathname === "/fr" ? (
                  <Button
                    type="submit"
                    className="loginButton"
                    variant="contained"
                  >
                    Connexion
                  </Button>
                ) : window.location.pathname === "/it" ? (
                  <Button
                    type="submit"
                    className="loginButton"
                    variant="contained"
                  >
                    Login
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="loginButton"
                    variant="contained"
                  >
                    Login
                  </Button>
                )}
              </Container>
            </form>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
