import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import addStation from '../../../assets/icons/addEclipse.svg';
import NewConnectingPoint from './NewConnectingPoint';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import RemoveCircleSharpIcon from '@material-ui/icons/RemoveCircleSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import TransportType from './TransportType';
import "./styles.css";

class Main extends Component {

  render() {
    // console.log('STATE: ', this.props);

    return (
      <React.Fragment>
        <CssBaseline />
        {
          this.props.state.connectingPointScreen === 'newPoint' ?
            <NewConnectingPoint state={this.props.state} handleConnectingPoint={this.props.handleConnectingPoint} />
          : this.props.state.connectingPointScreen === 'transportType' ?
            <TransportType 
              state={this.props.state} 
              handleHomeScreen={this.props.handleHomeScreen} 
              handleStartTravel={this.props.handleStartTravel} 
              handleEndTravel={this.props.handleEndTravel} 
              handleNewJourney={this.props.handleNewJourney}
              handleExtraStationsState={this.props.handleExtraStationsState}
              stationsPopper={this.props.stationsPopper}
              handleViaStationTravelType={this.props.handleViaStationTravelType}
              viaStationSubmit={this.props.viaStationSubmit}
              loadingOn={this.props.loadingOn}
              loadingOff={this.props.loadingOff} />
          : <Container maxWidth="false" className="StartortScreen" disableGutters="true">
              <Container maxWidth="false" disableGutters className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                  {window.location.pathname.includes('/fr') ?  'Veuillez cliquer sur le + et saisir' : window.location.pathname.includes('/it') ? 'Clicchi sul simbolo «più» e registri' : 'Bitte klicken Sie auf das Plus und erfassen'}
                </Typography>
                <Typography variant="h6" className="homeBannerText">
                  {window.location.pathname.includes('/fr') ?  'individuellement chaque lieu de correspondance' : window.location.pathname.includes('/it') ? 'ogni punto di cambio singolarmente' : 'Sie jeden Umsteigeort einzeln'}
                </Typography>
              </Container>
              <Container maxWidth="false" className="connectingStation">
                <Typography variant="h5">
                  {this.props.state.startPointName}
                </Typography>
                <Typography>
                  {
                    this.props.state.ExtraStops === null ?
                      <img onClick={() => this.props.handleConnectingPointScreen('newPoint', 0)} src={addStation} alt="Add Station" className="addConnectingStation" />
                    : <div>
                        <img onClick={() => this.props.handleConnectingPointScreen('newPoint', 0)} src={addStation} alt="Add Station" className="addConnectingStation" height="98px" />
                        {
                          this.props.state.initExtraStops.map( (stop, index) => (
                            <Typography variant="h5" className="connectionStop">
                              {stop.ViaStationName} <RemoveCircleSharpIcon onClick={() => this.props.removeInitConnectingPoint(stop.ViaStationName)} />
                              <br />
                              <img onClick={() => this.props.handleConnectingPointScreen('newPoint', index + 1)} src={addStation} alt="Add Station" className="addConnectingStation" height="98px" />
                            </Typography>
                          ))
                        }
                      </div>
                  }
                </Typography>
                <Typography variant="h5">
                  {this.props.state.endPointName}
                </Typography>
              </Container>
              <BottomNavigation onClick={() => this.props.handleConnectingPointScreen('transportType')} className="homeCompleteButton connectingContinueButton" style={{backgroundColor: "#EEEEEE"}}>
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  {window.location.pathname.includes('/fr') ?  'Aller aux moyens de transport' : window.location.pathname.includes('/it') ? 'Avanti ai mezzi di trasporto' : 'Weiter zu den Transportmitteln'}
                </Typography>
                <ArrowForwardIosSharpIcon />
              </BottomNavigation>
            </Container>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(Main);