import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DatePicker } from "@material-ui/pickers";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from '@material-ui/core';
import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import "./styles.css";

export const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	}
});

const CustomToolbar = function (props) {

	const { date,
		isLandscape,
		openView,
		setOpenView,
		title} = props;

	const handleChangeViewClick = (view) => (e) => {

		setOpenView(view);

	}

	const classes = useStyles();

	return (
		<PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
			<ToolbarButton
				onClick={handleChangeViewClick("year")}
				variant="h6"
				label={date.format("YYYY")}
				selected={openView === "year"}
			/>
			<ToolbarButton
				onClick={handleChangeViewClick("date")}
				variant="h4"
				selected={openView === "date"}
				label={date.format("dddd, D. MMM")}
			/>
		</PickerToolbar>
	);

}

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfile: [],
            datesRange: []
        }
      }
      
    componentDidMount() {
        this.handleUser();
    }

    handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://api.ga20.dev.mxm.ch/api/authentication/userInfo'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                userProfile: result.Preload,
            });
        })
        .catch(error => console.log('error', error));
    }

  render() {
    //   console.log('DATUM PROPS: ', this.state.userProfile);
    //   console.log('DATUM PROPS: ', this.state.userProfile);
      // eslint-disable-next-line no-lone-blocks
      {
        window.location.pathname.includes('/fr') ?
          moment.locale('fr')
        : window.location.pathname.includes('/it') ?
          moment.locale('it')
        :
          moment.locale('de')
      }
      var date = new Date(this.state.userProfile.PeriodEnd);
      var dateStart = moment(this.state.userProfile.PeriodStart);
    //   var difference = dateStart.diff(date, 'days');
      var difference = 0;
      var diffDays = [];

      if (new Date(this.state.userProfile.PeriodEnd) > new Date()) {
        difference = dateStart.diff(new Date(), 'days');
      } else {
        difference = dateStart.diff(date, 'days');
      }

      while (difference < 0) {
            // console.log('WHILE difference', difference);
            // console.log('WHILE diffDays', diffDays);
          diffDays.push(difference);
          difference ++;
      };
      
    //   console.log('date: ', date);
    //   console.log('dateStart', dateStart);
    //   console.log('difference', difference);
    //   console.log('diffDays', diffDays);

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="datumScreen" disableGutters="true">
            <Container maxWidth="false" disableGutters="true" className="homeBanner">
                {
                    window.location.pathname.includes('/fr') ?
                    <div>
                        <Typography variant="h6" className="homeBannerText">
                            Veuillez sélectionner la date
                        </Typography>
                        <Typography variant="h6" className="homeBannerText">
                            du voyage
                        </Typography>
                    </div>
                    : window.location.pathname.includes('/it') ?
                    <div>
                        <Typography variant="h6" className="homeBannerText">
                            Selezioni la data del
                        </Typography>
                        <Typography variant="h6" className="homeBannerText">
                            suo viaggio
                        </Typography>
                    </div>
                    :
                    <div>
                        <Typography variant="h6" className="homeBannerText">
                            Bitte wählen Sie das Datum der
                        </Typography>
                        <Typography variant="h6" className="homeBannerText">
                            Reise aus
                        </Typography>
                    </div>
                }
            </Container>
            <Container>
                <Button variant="contained" className="calendarButton" disableElevation size="large" fullWidth>
                    <DatePicker
                        autoOk="false"
                        label="Clearable"
                        inputVariant="filled"
                        showToolbar
                        okLabel={window.location.pathname.includes('/fr') ?  'Continuer' : window.location.pathname.includes('/it') ? 'Avanti' : 'Weiter'}
                        cancelLabel={window.location.pathname.includes('/fr') ?  'Interrompre' : window.location.pathname.includes('/it') ? 'Annullare' : 'Abbrechen'}
                        TextFieldComponent={'button'}
                        minDate={new Date(this.props.state.userProfile.PeriodStart)}
                        maxDate={new Date(this.props.state.userProfile.PeriodEnd)}
                        value={this.props.state.date}
                        onChange={this.props.handleDateChange}
                        ToolbarComponent={CustomToolbar}
                    />
                    {window.location.pathname.includes('/fr') ?  'Ouvrir le calendrier' : window.location.pathname.includes('/it') ? 'Aprire il calendario' : 'Kalender öffnen'}
                </Button>
                <div>
                    
                    
                    {
                        date > new Date() ?
                        <div className="oppositeDates">
                            {
                                diffDays.map( i => (
                                    <Button onClick={() => this.props.handleDateChange(new Date(moment().subtract(+Math.abs(i), 'day')))} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                        {moment().subtract(+Math.abs(i), 'day').format('dddd, D. MMM. YYYY')}
                                    </Button>
                                ))
                            }
                            <Button onClick={() => this.props.handleDateChange(new Date())} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                {window.location.pathname.includes('/fr') ?  'Aujourd\'hui' : window.location.pathname.includes('/it') ? 'Oggi' : 'Heute'}
                            </Button>
                        </div>
                        : <div className="oppositeDates">
                            {
                                diffDays.map( i => (
                                    <Button onClick={() => this.props.handleDateChange(new Date(moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i), 'day')))} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                        {moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i), 'day').format('dddd, D. MMM. YYYY')}
                                    </Button>
                                    ))
                            }
                            <Button onClick={() => this.props.handleDateChange(new Date(moment(this.props.state.userProfile.PeriodEnd)))} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                {moment(this.props.state.userProfile.PeriodEnd).format('dddd, D. MMM. YYYY')}
                            </Button>
                          </div>
                    }
                </div>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);