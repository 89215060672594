import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import NavBar from '../../Shared/NavBar';
import Main from './Main';

class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
          page: 'FAQ',
          back: false,
          value: 'information'
        }
      }

      componentDidMount() {
        // this.isUserAuthenticated();
    }
    
    isUserAuthenticated = () => {
        var auth = localStorage.AccessToken
        if (auth === undefined) {
            localStorage.clear();
            this.props.history.push('/')
        }
    }

    backButtonHandler = () => {
        if (this.state.page === 'Fragen und Hilfe') {
          this.props.history.push('home')
        }
    }

    render() {
        // console.log('INFOS PAGE STATE: ', this.state);
        
        return(
            <React.Fragment>
                <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
                <Main />
                <NavBar state={this.state} />
            </React.Fragment>
        )
    }
}

export default withRouter(Information);