import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import aspLogo from '../../assets/images/aspLogo.png';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.css';

class Main extends Component {

  render() {
    return (
      <div className="makeStyles-root-1">
        <AppBar position="sticky" elevation="0" >
          <Backdrop>
            <CircularProgress color="primary" />
          </Backdrop>
          <Toolbar>
            <IconButton edge="start" className="MuiButtonBase-root MuiIconButton-root makeStyles-menuButton-2 MuiIconButton-colorInherit MuiIconButton-edgeStart" color="inherit" aria-label="menu">  
              {
                this.props.state.back === true ?
                  <ArrowBackIosSharpIcon onClick={this.props.backButtonHandler} />
                : null
              }
            </IconButton>
            <Typography variant="h6" className="title">
            </Typography>
            <Typography variant="h6" className="title">
              {this.props.state.page}
            </Typography>
            <img src={aspLogo} width="54px" alt="Logo" />
          </Toolbar>
        </AppBar>
        <AppBar position="fixed" elevation="0">
          <Backdrop className="loadingBackdrop" open={this.props.state.loading} >
            {
              window.location.pathname.includes('/fr') ?
                <Typography variant="subtitle2">
                  <CircularProgress color="primary" />
                  <br />
                  Nous traitons vos données.
                  <br />
                  Veuillez patienter un instant.
                </Typography>
              : window.location.pathname.includes('/it') ?
                <Typography variant="subtitle2">
                  <CircularProgress color="primary" />
                  <br />
                  Elaboriamo i vostri dati.
                  <br />
                  Vi prego di avere pazienza per un momento.
                </Typography>
              :
                <Typography variant="subtitle2">
                  <CircularProgress color="primary" />
                  <br />
                  Wir verarbeiten Ihre Daten.
                  <br />
                  Bitte haben Sie einen Moment Geduld.
                </Typography>
            }
          </Backdrop>
          <Toolbar>
            <IconButton edge="start" className="MuiButtonBase-root MuiIconButton-root makeStyles-menuButton-2 MuiIconButton-colorInherit MuiIconButton-edgeStart" color="inherit" aria-label="menu">  
              {
                this.props.state.back === true ?
                  <ArrowBackIosSharpIcon onClick={this.props.backButtonHandler} />
                : null
              }
            </IconButton>
            <Typography variant="h6" className="title">
            </Typography>
            <Typography variant="h6" className="title">
              {this.props.state.page}
            </Typography>
            <img src={aspLogo} width="54px" alt="Logo" />
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default Main;