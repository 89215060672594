import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import "./styles.css";
import { Toolbar } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IOSSwitch from './IOSSwitch';
import querystring from 'querystring';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: [],
      editting: false,
      firstname: '',
      lastname: '',
      sex: '',
      address1: ' ',
      address2: ' ',
      zip: '',
      place: '',
      country: '',
      email: '',
      emailNotifications: false,
      pushNotifications: false
    }
  }

  componentDidMount() {
    this.handleUser();
    setTimeout(()=>{
      this.setStates();
    }, 1000)
    setTimeout(()=>{
      this.setStates();
    }, 2000)
    setTimeout(()=>{
      this.setStates();
    }, 3000)
    // eslint-disable-next-line
    var truepush = window.truepush || [];
    // console.log(truepush);
}

  setStates = () => {
    this.setState({
      firstname: this.state.userProfile.Firstname,
      lastname: this.state.userProfile.Name,
      sex: this.state.userProfile.Sex,
      address1: this.state.userProfile.Address1,
      address2: this.state.userProfile.Address2,
      zip: this.state.userProfile.ZipCode,
      place: this.state.userProfile.Place,
      country: this.state.userProfile.Country,
      email: this.state.userProfile.Email,
      emailNotifications: this.state.user.EnableDailyMailReminder,
      pushNotifications: this.state.user.EnablePushNotificationReminder
    })
  }

  handleChangePush = (e) => {
    var truepush = window.truepush || [];
    let pushId;
    const thisV = this;
    // console.log(truepush);

    if (Notification.permission === "granted") {
      if (this.state.pushNotifications === false) {
        truepush.subscribeUserVapid();
        this.setState({
          pushNotifications: true
        })
        this.updateLanguage(this.state.userProfile.Language, this.state.user.EnableDailyMailReminder, true);
        setTimeout(()=>{
          window.location.reload();
        }, 2000)
      } else {
        truepush.GetSubscriberId(function(error, subscriberId) {
          pushId = subscriberId;
        });
        
        var targetUrl = 'https://app.truepush.com/api/v1/deleteUser'
  
        var myHeaders = new Headers();
        myHeaders.append("authorization", 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkRGF0ZSI6MTU5MTk2NjMwMDI2MywiaWQiOiI1ZWUzN2E1Y2FjZDcxNzQ2ZjdmYzI2YmIiLCJ1c2VySWQiOiI1ZWUzNzc5MGJiYmRkMmNhNjBhNzE3ZjkiLCJpYXQiOjE1OTE5NjYzMDB9.HXUHVnAhzYwBIxOc2Kto3jrMziOPAh-_9rbeAiROBOY');
        myHeaders.append("content-type", "application/json");
  
        var raw = JSON.stringify({
          "userId": pushId
        });
  
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        };
  
        fetch(targetUrl, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
  
  
        this.setState({
          pushNotifications: false
        })
        this.updateLanguage(this.state.userProfile.Language, this.state.user.EnableDailyMailReminder, false);
        setTimeout(()=>{
          window.location.reload();
        }, 2000)
      }
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          if (thisV.state.pushNotifications === false) {
            truepush.subscribeUserVapid();
            thisV.setState({
              pushNotifications: true
            })
            thisV.updateLanguage(thisV.state.userProfile.Language, thisV.state.user.EnableDailyMailReminder, true);
            setTimeout(()=>{
              window.location.reload();
            }, 2000)
          } else {
            truepush.GetSubscriberId(function(error, subscriberId) {
              pushId = subscriberId;
            });
            
            var targetUrl = 'https://app.truepush.com/api/v1/deleteUser'
      
            var myHeaders = new Headers();
            myHeaders.append("authorization", 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkRGF0ZSI6MTU5MTk2NjMwMDI2MywiaWQiOiI1ZWUzN2E1Y2FjZDcxNzQ2ZjdmYzI2YmIiLCJ1c2VySWQiOiI1ZWUzNzc5MGJiYmRkMmNhNjBhNzE3ZjkiLCJpYXQiOjE1OTE5NjYzMDB9.HXUHVnAhzYwBIxOc2Kto3jrMziOPAh-_9rbeAiROBOY');
            myHeaders.append("content-type", "application/json");
      
            var raw = JSON.stringify({
              "userId": pushId
            });
      
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw
            };
      
            fetch(targetUrl, requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error', error));
      
      
            thisV.setState({
              pushNotifications: false
            })
            thisV.updateLanguage(thisV.state.userProfile.Language, thisV.state.user.EnableDailyMailReminder, false);
            setTimeout(()=>{
              window.location.reload();
            }, 2000)
          }
        }
      });
    }
    
    
  }

  handleChangeEmail = () => {
    if (this.state.emailNotifications === false) {
      this.setState({
        emailNotifications: true
      })
      this.updateLanguage(this.state.userProfile.Language, true, this.state.user.EnablePushNotificationReminder);
      setTimeout(()=>{
        window.location.reload();
      }, 2000)
    } else {
      this.setState({
        emailNotifications: true
      })
      this.updateLanguage(this.state.userProfile.Language, false, this.state.user.EnablePushNotificationReminder);
      setTimeout(()=>{
        window.location.reload();
      }, 2000)
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }
  
  handleUser = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://api.ga20.dev.mxm.ch/api/authentication/userInfo'

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(proxyUrl + targetUrl, requestOptions)
    .then(response => response.json())
    .then(result => {
        this.setState({
            userProfile: result.Preload,
            user: result
        });
    })
    .catch(error => console.log('error', error));
}

handleSubmit = (pin, name) => {
  axios.post('https://api.ga20.dev.mxm.ch/token', querystring.stringify({
    Pin: pin,
    Lastname: name,
    grant_type: 'password'
  }))

    .then(response => {
      window.localStorage.setItem('AccessToken', 'Bearer ' + response.data.access_token);
    })
    .catch(error => console.log('error', error));
}

 editStateHandler = () => {
   this.setState({
     editting: !this.state.editting
   })
 }

 onChangeHandler = (e) => {
  this.setState({ [e.target.name]: e.target.value });
 }

 apiCall = () => {
   var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://api.ga20.dev.mxm.ch/api/authentication/EditUser'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "lang=de");

        var raw = JSON.stringify({
            "Id": this.state.user.Id,
            "EnableDailyMailReminder": this.state.user.EnableDailyMailReminder,
            "EnablePushNotificationReminder": this.state.user.EnablePushNotificationReminder,
            "Name": this.state.user.Name,
            "IsAdmin": false,
            "IsRegistered": true,
            "Preload": {
              "UserEmail": this.state.userProfile.Email,
              "Salutation": this.state.userProfile.Salutation,
              "Pin": this.state.userProfile.Pin,
              "PeriodStart": this.state.userProfile.PeriodStart,
              "PeriodEnd": this.state.userProfile.PeriodEnd,
              "SubmissionDeadline": this.state.userProfile.SubmissionDeadline,
              "Email": this.state.email,
              "ChangedBy": this.state.userProfile.Firstname + ' ' + this.state.userProfile.Name,
              "MailSalutation": this.state.userProfile.MailSalutation,
              "NoTravelsReason": this.state.userProfile.NoTravelsReason,
              "Notes": this.state.userProfile.Notes,
              "UniqueId": this.state.userProfile.UniqueId,
              "ContestParticipation": this.state.userProfile.ContestParticipation,
              "ImportDate": this.state.userProfile.ImportDate,
              "ActivationDate": this.state.userProfile.ActivationDate,
              "LockDate": this.state.userProfile.LockDate,
              "ReiseblattNr": this.state.userProfile.ReiseblattNr,
              "Sex": this.state.sex,
              "Firstname": this.state.firstname,
              "Name": this.state.name,
              "Address1": this.state.address1,
              "Address2": this.state.address2,
              "ZipCode": this.state.zip,
              "Place": this.state.place,
              "Country": this.state.country,
              "Language": this.state.userProfile.Language,
              "ProjectCode": this.state.userProfile.ProjectCode,
              "AboType": this.state.userProfile.AboType,
              "LockSource": this.state.userProfile.LockSource,
              "Id": this.state.userProfile.Id,
              "Created": this.state.userProfile.Created,
              "Changed": new Date()
            }
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.text())
        .then(localStorage.clear())
        .then(this.handleSubmit(this.state.userProfile.Pin, this.state.userProfile.Name))
        .catch(error => console.log('error', error));
 }

 apiCallLanguage = (e, n, p) => {
  var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
           targetUrl = 'https://api.ga20.dev.mxm.ch/api/authentication/EditUser'

       var myHeaders = new Headers();
       myHeaders.append("Authorization", window.localStorage.AccessToken);
       myHeaders.append("Content-Type", "application/json");
       myHeaders.append("Cookie", "lang=de");

       var raw = JSON.stringify({
           "Id": this.state.user.Id,
           "Name": this.state.user.Name,
           "IsAdmin": false,
           "IsRegistered": true,
           "EnableDailyMailReminder": n,
           "EnablePushNotificationReminder": p,
           "Preload": {
             "UserEmail": this.state.userProfile.Email,
             "Salutation": this.state.userProfile.Salutation,
             "Pin": this.state.userProfile.Pin,
             "PeriodStart": this.state.userProfile.PeriodStart,
             "PeriodEnd": this.state.userProfile.PeriodEnd,
             "SubmissionDeadline": this.state.userProfile.SubmissionDeadline,
             "Email": this.state.userProfile.Email,
             "ChangedBy": this.state.userProfile.Firstname + ' ' + this.state.userProfile.Name,
             "MailSalutation": this.state.userProfile.MailSalutation,
             "NoTravelsReason": this.state.userProfile.NoTravelsReason,
             "Notes": this.state.userProfile.Notes,
             "UniqueId": this.state.userProfile.UniqueId,
             "ContestParticipation": this.state.userProfile.ContestParticipation,
             "ImportDate": this.state.userProfile.ImportDate,
             "ActivationDate": this.state.userProfile.ActivationDate,
             "LockDate": this.state.userProfile.LockDate,
             "ReiseblattNr": this.state.userProfile.ReiseblattNr,
             "Sex": this.state.userProfile.Sex,
             "Firstname": this.state.userProfile.Firstname,
             "Name": this.state.userProfile.Name,
             "Address1": this.state.userProfile.Address1,
             "Address2": this.state.userProfile.Address2,
             "ZipCode": this.state.userProfile.ZipCode,
             "Place": this.state.userProfile.Place,
             "Country": this.state.userProfile.Country,
             "Language": e,
             "ProjectCode": this.state.userProfile.ProjectCode,
             "AboType": this.state.userProfile.AboType,
             "LockSource": this.state.userProfile.LockSource,
             "Id": this.state.userProfile.Id,
             "Created": this.state.userProfile.Created,
             "Changed": new Date()
           }
       });

       var requestOptions = {
           method: 'PUT',
           headers: myHeaders,
           body: raw,
           redirect: 'follow'
       };
       
       fetch(proxyUrl + targetUrl, requestOptions)
       .then(response => response.text())
       .then(localStorage.clear())
       .then(this.handleSubmit(this.state.userProfile.Pin, this.state.userProfile.Name))
       .catch(error => console.log('error', error));
}

 updatePersonal = () => {
  this.editStateHandler();
  this.props.loadingScreen();
  this.apiCall();
  setTimeout(()=>{
    this.apiCall();
  }, 2000)
  setTimeout(()=>{
    window.location.reload()
  }, 3000)
  }

 updateLanguage = (e, n, p) => {
  this.props.loadingScreen();
  this.apiCallLanguage(e, n, p);
  setTimeout(()=>{
    this.apiCallLanguage(e, n, p);
  }, 1000)
  setTimeout(()=>{
    if (e === 1 && window.location.host !== 'ga.allianceswisspass.ch') {
      localStorage.clear();
      setTimeout(()=>{
        window.location.href = 'https://ga.allianceswisspass.ch/de?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
      }, 2000)
    }
    if (e === 2 && window.location.host !== 'ag.allianceswisspass.ch') {
      localStorage.clear();
      setTimeout(()=>{
        window.location.href = 'https://ag.allianceswisspass.ch/fr?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
      }, 2000)
    }
    if (e === 3 && window.location.host !== 'agquestionario.allianceswisspass.ch') {
      localStorage.clear();
      setTimeout(()=>{
        window.location.href = 'https://agquestionario.allianceswisspass.ch/it?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
      }, 2000)
    }
  }, 3000)
  }

  logOut = () => {
    localStorage.clear();
    window.location.reload();
  }

  render() {
    // console.log('SETTINGS STATE: ', this.state);
    
    return (
      <React.Fragment>
        <CssBaseline />
        <Container className="settingsScreen" maxWidth="false" disableGutters="true">
            <Container maxWidth="false" disableGutters="true" className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                  {window.location.pathname.includes('/fr') ?  'Vous pouvez modifier ici vos coordonnées pour l\'enquête et vos réglages de notifications.' : window.location.pathname.includes('/it') ? 'Qui può modificare i suoi dati di contatto per il sondaggio e le impostazioni delle notifiche.' : 'Hier können Sie Ihre Kontaktdaten für die Umfrage und die Benachrichtigungseinstellungen bearbeiten.'}
                </Typography>
            </Container>
            {
              this.state.editting === true ?
              <div>
                <Container className="settingsTopSection">
                  <Toolbar disableGutters="true" className="settingsToolbar">
                    <Container disableGutters="true">
                      <TextField onChange={this.onChangeHandler} name="firstname" className="editFields50" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Prénom' : window.location.pathname.includes('/it') ? 'Nome' : 'Vorname'} value={this.state.firstname} variant="outlined" />
                      <TextField disabled className="editFields50" onChange={this.onChangeHandler} name="lastname" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Nom' : window.location.pathname.includes('/it') ? 'Cognome' : 'Name'} value={this.state.lastname} variant="outlined" />
                      <FormControl variant="outlined" className="editFields100">
                        <InputLabel>{window.location.pathname.includes('/fr') ?  'Formule' : window.location.pathname.includes('/it') ? 'Appellativo' : 'Anrede'}</InputLabel>
                        <Select name="sex" value={this.state.sex} onChange={this.onChangeHandler} label={window.location.pathname.includes('/fr') ?  'Formule' : window.location.pathname.includes('/it') ? 'Appellativo' : 'Anrede'} >
                          <MenuItem value={1}>{window.location.pathname.includes('/fr') ?  'Monsieur' : window.location.pathname.includes('/it') ? 'Signor' : 'Herr'}</MenuItem>
                          <MenuItem value={2}>{window.location.pathname.includes('/fr') ?  'Madame' : window.location.pathname.includes('/it') ? 'Signora' : 'Frau'}</MenuItem>
                        </Select>
                      </FormControl>
                      {
                        this.state.userProfile.Address1.length > 0 ?
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address1" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Adresse' : window.location.pathname.includes('/it') ? 'Indirizzo' : 'Adresse'} value={this.state.address1} variant="outlined" />
                        :
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address1" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Adresse' : window.location.pathname.includes('/it') ? 'Indirizzo' : 'Adresse'} variant="outlined" />
                      }
                      {
                        this.state.userProfile.Address2.length > 1 ?
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address2" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Complément d\'adresse' : window.location.pathname.includes('/it') ? 'Supplemento di indirizzo' : 'Adresszusatz'} value={this.state.address2} variant="outlined" />
                        :
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address2" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Complément d\'adresse' : window.location.pathname.includes('/it') ? 'Supplemento di indirizzo' : 'Adresszusatz'} variant="outlined" />
                      }
                      <TextField className="editFields30" onChange={this.onChangeHandler} name="zip" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'NPA' : window.location.pathname.includes('/it') ? 'CAP ' : 'PLZ'} value={this.state.zip} variant="outlined" />
                      <TextField className="editFields50" onChange={this.onChangeHandler} name="place" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Lieu de résidence' : window.location.pathname.includes('/it') ? 'Residenza' : 'Wohnort'} value={this.state.place} variant="outlined" />
                      <TextField className="editFields20" onChange={this.onChangeHandler} name="country" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Pays' : window.location.pathname.includes('/it') ? 'Paese ' : 'Land'} value={this.state.country} variant="outlined" />
                    </Container>
                  </Toolbar>
                </Container>
                <Container>
                <TextField className="settingsTextField editFields100" onChange={this.onChangeHandler} name="email" id="outlined-basic" placeholder="Please enter email" label="E-Mail" value={this.state.email} variant="outlined" fullWidth />
                <Button onClick={() => this.updatePersonal()} className="settingsButton" disableElevation variant="contained">{window.location.pathname.includes('/fr') ?  'Mise à jour' : window.location.pathname.includes('/it') ? 'Update' : 'Update'}</Button>
                </Container>
              </div>   
              :
              <div>
                <Container className="settingsTopSection">
                  <Toolbar>
                    <Typography>
                      <Avatar className="settingsAvatar"></Avatar>
                    </Typography>
                    <Container className="topDetailsSection">
                      <Typography className="userInformation">
                        {this.state.userProfile.Firstname + ' ' + this.state.userProfile.Name}
                      </Typography>
                      {
                        this.state.userProfile.Sex === 1 ?
                          <Typography >
                            {window.location.pathname.includes('/fr') ?  'Monsieur' : window.location.pathname.includes('/it') ? 'Signor' : 'Herr'}
                          </Typography>
                        : this.state.userProfile.Sex === 2 ?
                          <Typography >
                            {window.location.pathname.includes('/fr') ?  'Madame' : window.location.pathname.includes('/it') ? 'Signora' : 'Frau'}
                          </Typography>
                        : null
                      }
                      <Typography className="userInformation">
                        {this.state.userProfile.Email}
                      </Typography>
                      <Typography className="userInformation">
                        {this.state.userProfile.Address1}
                      </Typography>
                      <Typography className="userInformation">
                        {this.state.userProfile.Address2}
                      </Typography>
                      <Typography className="userInformation">
                        {this.state.userProfile.ZipCode + ' ' + this.state.userProfile.Place + ' ' + this.state.userProfile.Country}
                      </Typography>
                      <Button onClick={() => this.editStateHandler()} className="settingsButton" disableElevation variant="contained">{window.location.pathname.includes('/fr') ?  'Modifier' : window.location.pathname.includes('/it') ? 'Modificare' : 'Bearbeiten'}</Button>
                    </Container>
                  </Toolbar>
                </Container>
                <Container>
                {
                  this.state.userProfile.Language === 1 ?
                  <Toolbar disableGutters className="languagesToolbar">
                    <Button onClick={() => this.updateLanguage(1, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined" disabled>DE</Button>
                    <Button onClick={() => this.updateLanguage(2, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">FR</Button>
                    <Button onClick={() => this.updateLanguage(3, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">IT</Button>
                  </Toolbar>
                  : this.state.userProfile.Language === 2 ?
                  <Toolbar disableGutters className="languagesToolbar">
                    <Button onClick={() => this.updateLanguage(1, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">DE</Button>
                    <Button onClick={() => this.updateLanguage(2, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined" disabled>FR</Button>
                    <Button onClick={() => this.updateLanguage(3, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">IT</Button>
                  </Toolbar>
                  : this.state.userProfile.Language === 3 ?
                  <Toolbar disableGutters className="languagesToolbar">
                    <Button onClick={() => this.updateLanguage(1, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">DE</Button>
                    <Button onClick={() => this.updateLanguage(2, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">FR</Button>
                    <Button onClick={() => this.updateLanguage(3, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined" disabled>IT</Button>
                  </Toolbar>
                  : 
                  <Toolbar disableGutters className="languagesToolbar">
                    <Button onClick={() => this.updateLanguage(1, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">DE</Button>
                    <Button onClick={() => this.updateLanguage(2, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">FR</Button>
                    <Button onClick={() => this.updateLanguage(3, this.state.user.EnableDailyMailReminder, this.state.user.EnablePushNotificationReminder)} className="languageButton" variant="outlined">IT</Button>
                  </Toolbar>
                }
              </Container>   
              </div>
            }
            <Container className="notificationsSection">
              <Container disableGutters>
                <Typography component="div" variant="p" className="notificationsParagraph">
                  {window.location.pathname.includes('/fr') ?  'Souhaitez-vous recevoir chaque jour un rappel de la saisie des voyages?' : window.location.pathname.includes('/it') ? 'Desidera ricevere un promemoria giornaliero per la registrazione dei viaggi?' : 'Möchten Sie eine tägliche Erinnerung für die Reiseerfassung erhalten?'}
                </Typography>
              </Container>
              <Container disableGutters>
              <Typography component="div" className="notificationTogglers">
                E-Mail
                <IOSSwitch checked={this.state.emailNotifications} onChange={this.handleChangeEmail} name="emailNotifications" inputProps={{ 'aria-label': 'email notifications' }} />
              </Typography>
              <Typography component="div" className="notificationTogglers">
                {window.location.pathname.includes('/fr') ?  'Notifications push' : window.location.pathname.includes('/it') ? 'Notifica push' : 'Push-Benachrichtigung'}
                <IOSSwitch  checked={this.state.pushNotifications} onChange={this.handleChangePush} name="pushNotifications" inputProps={{ 'aria-label': 'push notifications' }} />
              </Typography>
              </Container>
            </Container>
              <BottomNavigation className="homeCompleteButton logOutButton" style={{backgroundColor: "#EEEEEE"}}>
                <BottomNavigationAction onClick={() => this.logOut()} label={window.location.pathname.includes('/fr') ?  'Déconnexion' : window.location.pathname.includes('/it') ? 'Effettuare il logout' : 'Ausloggen'} />
              </BottomNavigation>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);