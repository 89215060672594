import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import 'moment/locale/de';
import "./styles.css";

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      tripsData: null,
      totalTrips: 0,
      datesRange: [],
      reason: null
    }
  }

  render() {
    // console.log('REASON PROPS: ', this.props);
    

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="datumScreen" disableGutters="true">
            {
              window.location.pathname.includes('/fr') ?
                <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
                  N'avez-vous vraiment pas utilisé votre abonnement général?
                </Typography>
              : window.location.pathname.includes('/it') ?
                <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
                  Davvero non ha utilizzato il suo abbonamento generale?
                </Typography>
              :
                <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
                  Haben Sie Ihr Generalabonnement wirklich nicht benutzt?
                </Typography>
            }
            <Container>
                <FormControl fullWidth component="fieldset">
                    <RadioGroup aria-label="gender" name="reason" onChange={this.props.reasonHandler} >
                        {
                          window.location.pathname.includes('/fr') ?
                            <FormControlLabel value="Andere Gründe" control={<Radio />} label="Non ho effettuato ALCUN viaggio con l'abbonamento generale in questo periodo (ad esempio a causa di deposito, vacanze ecc.)" />
                          : window.location.pathname.includes('/it') ?
                            <FormControlLabel value="Andere Gründe" control={<Radio />} label="Je n'ai PAS effectué de voyage avec mon abonnement général pendant cette période (p. ex. en raison d'un dépôt de l'abonnement ou de vacances)." />
                          :
                            <FormControlLabel value="Andere Gründe" control={<Radio />} label="Ich habe KEINE Reise mit dem Generalabonnement in diesem Zeitraum unternommen (beispielsweise aufgrund Hinterlegung, Ferien etc.)." />
                        }
                        {/* <FormControlLabel value="GA hinterlegt" control={<Radio />} label="GA hinterlegt" />
                        <FormControlLabel value="Militärdienst / Zivildienst" control={<Radio />} label="Militärdienst / Zivildienst" />
                        <FormControlLabel value="Krankheit/Unfall" control={<Radio />} label="Krankheit/Unfall" />
                        <FormControlLabel value="Andere" control={<Radio />} label="Andere" /> */}
                    </RadioGroup>
                    {/* <TextField disabled={ this.props.state.reason === 'Andere' ? false : true } fullWidth onChange={this.props.reasonHandler} name="reasonFreeText" id="outlined-basic" label="Grund eingeben" value={this.props.state.reasonFreeText} variant="outlined" /> */}
                </FormControl>
            </Container>
        </Container>
        <BottomNavigation onClick={() => this.props.dialogHandler(true)} className="homeCompleteButton connectingContinueButton" style={{backgroundColor: "#EEEEEE"}}>
            {
              window.location.pathname.includes('/fr') ?
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  Finaliser le journal de voyage
                </Typography>
              : window.location.pathname.includes('/it') ?
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  Concludere il giornale di viaggio.
                </Typography>
              :
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  Reisejournal abschliessen
                </Typography>
            }
            <ArrowForwardIosSharpIcon />
        </BottomNavigation>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);