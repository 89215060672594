import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import * as moment from 'moment';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import Reason from './Reason';
import Contest from './Contest';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import 'moment/locale/de';
import "./styles.css";

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      tripsData: null,
      totalTrips: 0,
      datesRange: [],
      screen: null,
      reason: null,
      finishDialog: false
    }
  }

  componentDidMount() {
    this.handleUser();
  }

  handleUser = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://api.ga20.dev.mxm.ch/api/Journeys/Journeys'

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(proxyUrl + targetUrl, requestOptions)
    .then(response => response.json())
    .then(result => {
        this.setState({
          tripsData: result,
          totalTrips: result.length
        });
    })
    .catch(error => console.log('error', error));
  }

  dialogHandle = () => {
    window.localStorage.setItem('dialog', 'firstTimeOut');
    this.setState({dialog: 'firstTimeOut'})
  }

  goToReason = () => {
    this.setState({
      screen: 'Reason'
    })
  }

  dialogHandler = (param) => {
    this.setState({
      finishDialog: param,
      screen: null
    })
  }

  dialogYes = () => {
    this.setState({
      screen: 'Contest'
    })
  }

  preFinish = () => {
    if (this.state.totalTrips > 0) {
      // console.log('FINISH JOURNEY');
      this.dialogHandler(true);
    } else {
      // console.log('ASK QUESTIONS');
      this.goToReason()
    }
  }

  reasonHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  finishJourneyCompetition = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://api.ga20.dev.mxm.ch/api/Periodapi/Close'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81");

    var raw = JSON.stringify({
        "Journeys": this.state.tripsData,
        "Notes":null,
        "Reason":this.state.reason,
        "ReasonFreetext":'Andere',
        "HeaderTitle":null,
        "CopyOverride":null,
        "PeriodStart":this.props.state.userProfile.PeriodStart,
        "PeriodEnd":this.props.state.userProfile.PeriodEnd,
        "SubmissionDeadline":this.props.state.userProfile.SubmissionDeadline
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(proxyUrl + targetUrl, requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));
  }

  finishJourney = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://api.ga20.dev.mxm.ch/api/Periodapi/Close'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81");

    var raw = JSON.stringify({
        "Journeys": this.state.tripsData,
        "Notes":null,
        "Reason":this.state.reason,
        "ReasonFreetext":'Andere',
        "HeaderTitle":null,
        "CopyOverride":null,
        "PeriodStart":this.props.state.userProfile.PeriodStart,
        "PeriodEnd":this.props.state.userProfile.PeriodEnd,
        "SubmissionDeadline":this.props.state.userProfile.SubmissionDeadline
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(proxyUrl + targetUrl, requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));


    this.finalizeJourney();
  }

  finalizeJourney = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://api.ga20.dev.mxm.ch/api/Periodapi/Finalize'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81");

    var raw = JSON.stringify({});

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(proxyUrl + targetUrl, requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));

    localStorage.clear();
    this.props.history.push('/vielendank')
  }

  render() {
      // console.log('Finish PROPS: ', this.props.state.userProfile);
      // console.log('Finish STATE: ', this.state);
      moment.locale('de');
      var date = moment(this.props.state.userProfile.PeriodEnd);
      var dateStart = moment(this.props.state.userProfile.PeriodStart);
      var difference = dateStart.diff(date, 'days');
      var diffDays = [];

      while (difference < 1) {
          diffDays.push(difference);
          difference ++;
      };

    return (
      <React.Fragment>
        <CssBaseline />
        {
          this.state.screen === 'Reason' ?
            <Reason dialogHandler={this.dialogHandler} reasonHandler={this.reasonHandler} state={this.state} finishJourney={this.finishJourney} />
          : this.state.screen === 'Contest' ?
            <Contest state={this.state} finishJourneyCompetition={this.finishJourneyCompetition} finalizeJourney={this.finalizeJourney} />
          :
          <Container maxWidth="false" className="datumScreen" disableGutters="true">
            {
              window.location.pathname.includes('/fr') ?
                <Dialog
                  open={this.state.finishDialog}
                  aria-labelledby="responsive-dialog-title">
                  <DialogTitle id="responsive-dialog-title">{"Souhaitez-vous participer à notre concours?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Parmi les participants, nous tirons au sort CHAQUE MOIS 10 bons Reka Rail d’une valeur totale de 50 francs. 
                      Le tirage au sort annuel a pour PRIX PRINCIPAL un AG de 1re classe ou un bon de votre choix d’une valeur de 6300 francs.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.finishJourney()} autoFocus  color="primary">
                      Non merci
                    </Button>
                    <Button onClick={() => this.dialogYes()} color="primary" autoFocus>
                      Je souhaite participer 
                    </Button>
                  </DialogActions>
                </Dialog>
              : window.location.pathname.includes('/it') ?
                <Dialog
                  open={this.state.finishDialog}
                  aria-labelledby="responsive-dialog-title">
                  <DialogTitle id="responsive-dialog-title">{"Desidera partecipare al nostro concorso?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      OGNI MESE sorteggiamo tra i partecipanti 10 buoni Reka Rail da 50 franchi ciascuno e come PREMIO PRINCIPALE 
                      un abbonamento generale di 1a classe o un buono a sua scelta del valore di 6300 franchi. A tale scopo abbiamo 
                      bisogno dei suoi dati di contatto.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.finishJourney()} autoFocus  color="primary">
                      No, grazie
                    </Button>
                    <Button onClick={() => this.dialogYes()} color="primary" autoFocus>
                      Desidero partecipare 
                    </Button>
                  </DialogActions>
                </Dialog>
              :
                <Dialog
                  open={this.state.finishDialog}
                  aria-labelledby="responsive-dialog-title">
                  <DialogTitle id="responsive-dialog-title">{"Möchten Sie an unserem Wettbewerb teilnehmen?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Unter den Teilnehmenden verlosen wir MONATLICH 10x Reka-Rail Gutscheine à 50.
                    </DialogContentText>
                    <DialogContentText>
                      – Franken und als HAUPTPREIS ein Generalabonnement <br /> 1. Klasse oder einen Gutschein Ihrer Wahl im Wert von 6300.
                    </DialogContentText>
                    <DialogContentText>
                      – Franken. Hierfür benötigen wir Ihre Kontaktdaten.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.finishJourney()} autoFocus  color="primary">
                      Nein danke
                    </Button>
                    <Button onClick={() => this.dialogYes()} color="primary" autoFocus>
                      Ich möchte teilnehmen
                    </Button>
                  </DialogActions>
                </Dialog>
            }
            <Container>
              <Card raised="true" elevation="3" className={"firstTimeDialog " + this.state.dialog}>
                <CardContent>
                  <Typography className="closeIcon" onClick={this.dialogHandle}>
                    <CloseIcon fontSize="large" />
                  </Typography>
                  {
                    window.location.pathname.includes('/fr') ?
                      <Typography variant="h5" className="finishDialogContent">
                        Avez-vous saisi TOUS vos voyages en transports publics 
                        (train, tram, bus, bateau, remontées mécaniques)? 
                        Pensez également aux courts trajets locaux. Une fois finalisé, 
                        le journal ne pourra plus être modifié.
                      </Typography>
                    : window.location.pathname.includes('/it') ?
                      <Typography variant="h5" className="finishDialogContent">
                        Ha registrato TUTTI i viaggi effettuati con mezzi di trasporto 
                        pubblici come ferrovia, tram, autobus, battello o funivia? 
                        Pensi anche alle corse brevi e locali. Dopo la conclusione 
                        non sono più ammesse modifiche.
                      </Typography>
                    :
                      <Typography variant="h5" className="finishDialogContent">
                        Haben Sie ALLE Reisen mit öffentlichen Verkehrsmitteln wie 
                        Bahn, Tram, Bus, Schiff oder Seilbahnen erfasst? Denken Sie 
                        dabei auch an kurze und lokale Fahrten. Nach dem Abschluss 
                        sind keine Änderungen mehr möglich.
                      </Typography>
                  }
                </CardContent>
              </Card>
            </Container>
            <Container maxWidth="false" disableGutters="true">
            {
              this.state.tripsData === null ?
                <div>
                  {/* <Typography component="div" variant="h6" className="welcomeBanner">
                    Bitte erfassen Sie ihre Reisen.
                  </Typography> */}
                  <Container maxWidth="false" disableGutters="true">
                    <Container maxWidth="false" disableGutters="true" className="tripsList">
                    {
                      diffDays.map( i => (
                        <List>
                          <ListItem className="listItem finishItem">
                          {
                              window.location.pathname.includes('/fr') ?
                                <ListItemText 
                                  primary="Aucun voyage enregistré."
                                  secondary={moment(date).add(i, 'day').format('D.MM.YYYY')} />
                              : window.location.pathname.includes('/it') ?
                                <ListItemText 
                                  primary="Nessun viaggio registrato."
                                  secondary={moment(date).add(i, 'day').format('D.MM.YYYY')} />
                              :
                                <ListItemText 
                                  primary="Keine Fahrt erfasst."
                                  secondary={moment(date).add(i, 'day').format('D.MM.YYYY')} />
                            }
                          </ListItem>
                        </List>
                      ))
                    }
                    </Container>
                  </Container>
                </div>
              : this.state.tripsData.length > 0 ?
                <div>
                  {/* <Typography component="div" variant="h6" className="welcomeBanner">
                    Sie haben bisher folgende Reisen erfasst
                  </Typography> */}
                  <Container maxWidth="false" disableGutters="true">
                    <Container maxWidth="false" disableGutters="true" className="tripsList">
                    {
                      diffDays.map(i => (
                        this.state.tripsData.map((trip, index) => (
                          moment(trip.JourneyDate).format('D.MM.YYYY') === moment(date).add(i, 'day').format('D.MM.YYYY') ?
                          <List>
                            <ListItem className="listItem">
                              <ListItemText 
                              primary={trip.JourneySummary} 
                              secondary={moment(trip.JourneyDate).format('D.MM.YYYY')} />
                            </ListItem>
                          </List>
                          : index === this.state.tripsData.length - 1 ?
                          <List>
                            <ListItem className="listItem finishItem">
                            {
                              window.location.pathname.includes('/fr') ?
                                <ListItemText 
                                  primary="Aucun voyage enregistré."
                                  secondary={moment(date).add(i, 'day').format('D.MM.YYYY')} />
                              : window.location.pathname.includes('/it') ?
                                <ListItemText 
                                  primary="Nessun viaggio registrato."
                                  secondary={moment(date).add(i, 'day').format('D.MM.YYYY')} />
                              :
                                <ListItemText 
                                  primary="Keine Fahrt erfasst."
                                  secondary={moment(date).add(i, 'day').format('D.MM.YYYY')} />
                            }
                            </ListItem>
                          </List>
                          : null
                        ))
                      ))
                    }
                    </Container>
                  </Container>
                </div>
              : <Container className="tripsList" maxWidth="false" disableGutters="true">
                  <Container disableGutters="true">
                    <List>
                      <ListItem className="topListItem">
                        <Skeleton animation="wave" className="skeletonText" />
                        <Typography className="spacer"></Typography>
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                      </ListItem>
                      <ListItem className="bottomListItem">
                        <Skeleton animation="wave" className="skeletonTextSecond" />
                      </ListItem>
                    </List>
                    <List>
                      <ListItem className="topListItem">
                        <Skeleton animation="wave" className="skeletonText" />
                        <Typography className="spacer"></Typography>
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                      </ListItem>
                      <ListItem className="bottomListItem">
                        <Skeleton animation="wave" className="skeletonTextSecond" />
                      </ListItem>
                    </List>
                    <List>
                      <ListItem className="topListItem">
                        <Skeleton animation="wave" className="skeletonText" />
                        <Typography className="spacer"></Typography>
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                      </ListItem>
                      <ListItem className="bottomListItem">
                        <Skeleton animation="wave" className="skeletonTextSecond" />
                      </ListItem>
                    </List>
                  </Container>
                </Container>
            }
            <BottomNavigation onClick={() => this.preFinish()} className="homeCompleteButton connectingContinueButton" style={{backgroundColor: "#EEEEEE"}}>
              {
                window.location.pathname.includes('/fr') ?
                  <Typography variant="body1" className="connectingContinueButtonContent" >
                    Finaliser le journal de voyage
                  </Typography>
                : window.location.pathname.includes('/it') ?
                  <Typography variant="body1" className="connectingContinueButtonContent" >
                    Concludere il giornale di viaggio.
                  </Typography>
                :
                  <Typography variant="body1" className="connectingContinueButtonContent" >
                    Reisejournal abschliessen
                  </Typography>
              }
              <ArrowForwardIosSharpIcon />
            </BottomNavigation>
          </Container>
        </Container>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(Main);